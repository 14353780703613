import React, {useState, useEffect} from 'react';
import { Table, Container } from 'reactstrap';
import Layout from '../../components/layout'
import axios from "axios";
import Moment from "react-moment"


const ReferralList = (props) => {


    const [appState, setAppState] = useState({
        loading: true,
        notes: null,
    });

    useEffect(() => {
        setAppState({ loading: true });
        let location = window.location
        const apiUrl = location.origin +'/api/getAllReferrals'; //'http://localhost:8888/api/getAllContacts';

        axios.get(apiUrl, { headers : 
            { crossdomain: true }
            }).then(
        (repos) => {
          const notes = repos.data.notes;
          setAppState({ loading: false, notes: notes });
        });
      }, [setAppState]);

    return (
        <Layout>
            <Container>
                <div className="table_data">
                <Table>
                    <thead>
                        <tr>
                            <th>Referred Date</th>
                            <th>User Name</th>
                            <th>User Email</th>
                            <th>User Phone</th>
                            <th>Referrar Name</th>
                            <th>Referrar Email</th>
                            <th>Referrar Phone</th>
                            <th>Skill</th>
                            <th>Resume</th>
                        </tr>
                    </thead>
                    <tbody>
                    {appState.loading !== true ? appState.notes.map((note) => {
                        var t = new Date( parseInt(note._ts/1000));
                        //var formatted = t.format("dd.mm.yyyy hh:MM:ss");
                        return (
                        <tr key={note._id}>
                            <td><Moment format="YYYY/MM/DD">{t}</Moment></td>
                            <td>{note.userName}</td>
                            <td>{note.userEmail}</td>
                            <td>{note.userPhone}</td>
                            <td>{note.referralName}</td>
                            <td>{note.referralEmail}</td>
                            <td>{note.referralPhone}</td>
                            <td>{note.referralSkill}</td>
                            <td>{note.referralUrl !== '' ? <a target='_blank' href={note.referralUrl}>Resume</a> : ""} </td>
                        </tr>
                        );
                    }): "loading.."}

                    </tbody>
                </Table>
                </div>
            </Container>
        </Layout>
    );
}

export default ReferralList;